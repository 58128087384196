<template>
  <div id="order-info-view">
    <div class="header">
      <div class="back" @click="back">
        <md-icon>arrow_back</md-icon>
      </div>
      <div class="title">{{ $t('order.details') }}</div>
      <div class="menu" @click="toggleMenu" :class="{ hide: !showMenuBtn }">
        <md-icon>menu</md-icon>
      </div>
    </div>

    <OrderCard state="full" :srcOrder="order" v-if="order" ref="card" />
  </div>
</template>

<script>
import OrderCard from '@/components/OrderCard.vue'

export default {
  props: ['id'],
  components: { OrderCard },
  data() {
    return {
      order: null,
      showMenuBtn: false,
    }
  },
  methods: {
    back() {
      if (this.$store.routerHistory.length == 0) this.$router.push('/')
      else
        this.$router.push(this.$store.routerHistory.pop().fullPath).then(() => {
          this.$store.routerHistory.pop()
        })
    },
    toggleMenu() {
      this.$refs.card.toggleMenu()
    },
  },
  mounted() {
    this.$api
      .get(`user/orders/${this.id}?availablePaymentMethods=true`)
      .then(res => {
        this.order = res.data.data
      })
  },
  updated() {
    if (this.$refs.card) {
      this.showMenuBtn = this.$refs.card.showMenuBtn
    }
  },
}
</script>

<style lang="scss">
#order-info-view {
  margin: 0 auto;
  max-width: 500px;

  .order-card {
    margin-top: 0;
  }

  .header {
    height: 60px;
    background-color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 15px;

    .back {
      cursor: pointer;
    }
    .menu {
      cursor: pointer;

      &.hide {
        pointer-events: none;
        cursor: default;
        opacity: 0;
      }
    }
    .title {
      font-size: 16px;
    }
  }
}
</style>
